<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Add Service</h1>
    </div>


    <v-card>
      <v-card-text>
        <service-details ref="details" :service="service" :organizations="organizations" :categories="categories"></service-details>
        <v-row>
          <v-col cols="12">

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="pl-10 pr-10"  color="success" @click="save" :loading="saving">Done

          <template v-slot:loader>
            <span>Saving...</span>
          </template>
        </v-btn>
        <v-btn text @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-15">
      <v-card-title>
        Documents
        <file-upload-button class="ml-10" @change="addAttachments" @newText="newText" button-text="Add Document" :files="newAttachments"></file-upload-button>
      </v-card-title>
      <v-card-text>
        <document-list :files="attachments" @delete="removeFile" @download="download" @change="hasChanges = true"></document-list>
      </v-card-text>
    </v-card>

    <text-document :document="newTextDoc" :show="docDialog" @save="saveTextDoc" @cancel="docDialog = false"></text-document>



  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import ServiceDetails from "@/components/ingram/service/ServiceDetails";
import DocumentList from "@/components/file/DocumentList";
import FileUploadButton from "@/components/file/FileUploadButton";
import {mapState, mapGetters} from 'vuex';
import navHelper from "@/mixins/NavMixin";
import TextDocument from "@/components/ingram/documents/TextDocument";

export default {
  name: "AddService",
  components:{ServiceDetails, FileUploadButton, DocumentList, TextDocument},
  mixins: [navHelper],
  data(){
    return {
      saving: false,
      stepper: 1,
      bar: 'Direct',
      valid: true,
      service:{direct:true, id:0, serviceTime:'08:00', serviceEndTime:'09:00', hours:1},
      attachments:[],
      textAttachments: [],
      newAttachments:[],
      newTextDoc:{name: '', content:''},
      docDialog: false,
      docText: '',
      currentFolder: null,
    }
  },
  computed:{
    ...mapState('app', ["organizations", "categories", "studentToken"]),
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
  },
  methods:{
    addAttachments(){
      const vm = this;
      vm.hasChanges = true;
      vm.newAttachments.forEach(x => {
        x.folder = vm.currentFolder;
        vm.attachments.push(x);
      });

      vm.newAttachments = [];
    },
    removeFile(file){
      let idx = this.attachments.indexOf(file);
      this.attachments.splice(idx,1);
    },
    newText(){
      this.docDialog = true;
    },
    saveTextDoc(){
      const vm = this;

      let doc = {
        name: vm.newTextDoc.name,
        description: vm.newTextDoc.content,
        thumbUrl: '/images/FileTypeTxt.png',
        image: null,
        edit: false,
        deleted: false,
        documentType: 2,
        folder: vm.currentFolder
      }

      vm.textAttachments.push(doc);
      vm.hasChanges = true;
      this.docDialog = false;
    },
    download(file){

    },
    cancel(){
      this.$router.push('/');
    },
    save(){
      const vm = this;
      vm.saving = true;
      //build form data
      let data = new FormData();

      let model = vm.service;
      model.textAttachments = vm.textAttachments;
      const tokens = [vm.studentToken, vm.user.identifier];
      model.studentToken = tokens.find(x => {return x !== ''});

      let metadata = [];

      vm.attachments.forEach((x, idx) => {
       if(!x.deleted && x.documentType === 1){
         data.append('photos', x.image, x.name);
         metadata.push({
           name: x.name,
           description: x.description,
           folder: x.folder,
         });
       }
      });

      model.photoMetadata = metadata;
      data.append('model', JSON.stringify(model));


      httpClient.post('/service/create', data)
      .then(function(response){
        if(response.data.success){
          vm.$root.$emit('saveSuccess', {message: 'Service Log Saved'});
          //vm.$router.go(-1);
          vm.goBack();
        }else{
          vm.$root.$emit('saveFailed', {message: response.data.message});
          vm.saving = false;
        }

      })
      .catch(function(error){
        vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
        vm.saving = false;
      });

    },
    validateDetails(){
      let isValid = this.$refs.details.validate();
      if(isValid) this.stepper = parseInt(this.stepper) + 1;
    },
    selectFolder(folder){
      console.log('page ' + folder);
      this.currentFolder = folder;
    }
  },
  mounted(){
    const vm = this;
    vm.$root.$on('folderselection', vm.selectFolder);
  },
  beforeDestroy() {
    const vm = this;
    vm.$root.$off('folderselection', vm.selectFolder);
  }
}
</script>

<style lang="scss">
</style>
