<template>
  <div>
    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Service</h1>
    </div>

    <v-row>
      <v-col cols="12" >
        <v-card>
          <v-card-text style="overflow: scroll">
            <service-list :service="service"></service-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import ServiceList from "@/components/ingram/service/ServiceList";

export default {
  name: "Service",
  components: {ServiceList},
  data(){
    return{
      service:[]
    }
  },
  mounted(){
    const vm = this;

    httpClient.get('/service')
    .then(function(response){
        vm.service = response.data;
    })
    .catch(function(error){

    });
  }
}
</script>

<style scoped>

</style>
