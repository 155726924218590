<template>
  <div>

    <div style="background-color: #A83640; color:white; padding:1rem; margin-bottom:20px;">
      <h1 class="text-h4">Summer Project</h1>
    </div>

    <summer-project-details :project="project" @hasChanges="dirty" @saved="saved"></summer-project-details>

  </div>
</template>

<script>
import httpClient from "@/plugins/axios";
import Swal from 'sweetalert2';
import SummerProjectDetails from '@/components/ingram/SummerProject/SummerProject';
import navHelper from "@/mixins/NavMixin";

export default {
  name: "SummerProject",
  components:{SummerProjectDetails},
  mixins:[navHelper],
  data(){
    return{
      hasChanges: false,
      project:{
        startDate: null,
        endDate: null,
        organization: null,
        contact: null,
        phone: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        categoryId: null,
        secondaryCategoryId: null,
        direct:false,
        description: null,
        website: null,
        attachments: []
      },

    }
  },
  computed:{

  },
  methods:{
    dirty(){
      this.hasChanges = true;
    },
    saved(){
      this.hasChanges=false;
      this.goBack();
    }
  },
  mounted(){
    //call home, get project or empty
    const vm = this;
    let url = '/summerproject';

    httpClient.get(url)
    .then(response => {
      if(response.data.id !== 0){
        vm.project = response.data;
      }
    })
    .catch(error => {
      console.log(error);
      vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
    });
  },
  beforeRouteLeave(to, from, next){
    const vm = this;
    if(this.hasChanges){
      Swal.fire({
        title: 'You have unsaved changes, save them now?',
        icon:'warning',
        showDenyButton: true,
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No'
      }).then((result) => {
        if(result.isConfirmed){
          vm.save();
          next();
        }else{
          next();
        }
      })
        .catch(() => next(false));
    }else {
      next();
    }
  }
}
</script>

<style scoped>

</style>
