<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-menu ref="menu1"
                        v-model="menus.startDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatStartDate"
                                  label="Project Start Date"
                                  hint="MM/DD/YYYY format"
                                  persistent-hint
                                  v-bind="attrs"
                                  v-on="on"
                                  autocomplete="off"
                                  required
                                  :rules="[v => !!v || 'Start Date is required']"
                                  outlined
                                  dense></v-text-field>
                  </template>
                  <v-date-picker v-model="project.startDate"
                                 required
                                 no-title
                                 label="Project Start Date"
                                 @input="menus.startDate = false"
                                 outlined
                                 dense></v-date-picker>
                </v-menu>

              </v-col>
              <v-col cols="4">
                <v-menu ref="menu2"
                        v-model="menus.endDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="formatEndDate"
                                  label="Project End Date"
                                  hint="MM/DD/YYYY format"
                                  persistent-hint
                                  v-bind="attrs"
                                  v-on="on"
                                  autocomplete="off"
                                  required
                                  :rules="[v => !!v || 'End Date is required']"
                                  outlined
                                  dense></v-text-field>
                  </template>
                  <v-date-picker v-model="project.endDate"
                                 required
                                 no-title
                                 label="Project End Date"
                                 @input="menus.endDate = false"
                                 outlined
                                 dense></v-date-picker>
                </v-menu>

              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-text-field v-model="project.organization"
                              label="Organization Name"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field v-model="project.contact"
                              label="Contact Name"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="project.phone"
                              label="Phone Number"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field v-model="project.address"
                              label="Address"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="project.address2"
                              label="Address 2"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">
                <v-text-field v-model="project.city"
                              label="City"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select v-model="project.state"
                          :items="states"
                          item-text="name"
                          item-value="id"
                          label="State"
                          outlined
                          dense
                          @change="dirty"></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="project.zip"
                              label="Zip"
                              required
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-text-field v-model="project.website"
                              label="Website"
                              outlined
                              dense
                              @change="dirty"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-select v-model="project.categoryId"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="Category"
                          required
                          :rules="[v => !!v || 'Category is required']"
                          outlined
                          dense></v-select>
              </v-col>

              <v-col cols="4">
                <v-select v-model="project.secondaryCategoryId"
                          :items="categories"
                          :clearable="true"
                          item-text="name"
                          item-value="id"
                          label="Secondary Category (optional)"
                          outlined
                          dense></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" md="4">
                <v-radio-group v-model="project.direct" row>
                  <template v-slot:label>
                    <div>Service Type:</div>
                  </template>
                  <v-radio label="Direct" :value="true"></v-radio>
                  <v-radio label="Indirect" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <editor v-model="project.description"  placeholder="Project Description" required></editor>
                <div v-if="!descriptionRule" style="margin-top:5px; padding-left:10px;">
                  <div class="v-text-field__details">
                    <div class="v-messages theme--light error--text" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">Description is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-btn class="pl-10 pr-10"  color="success" @click="save" :loading="saving">Save

              <template v-slot:loader>
                <span>Saving...</span>
              </template>
            </v-btn>
            <v-btn text @click="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-15">
          <v-card-title>
            Documents
            <file-upload-button class="ml-10" @change="newFiles" @newText="newText" button-text="Add Document" :files="newAttachments"></file-upload-button>
          </v-card-title>
          <v-card-text>
            <document-list :files="project.attachments" @delete="removeFile" @download="download" @change="hasChanges = true"></document-list>
          </v-card-text>
        </v-card>


      </v-col>
    </v-row>


    <text-document :document="newTextDoc" :show="docDialog" @save="saveTextDoc" @cancel="docDialog = false"></text-document>

  </div>
</template>

<script>
import states from '@/data/states'
import Editor from '@/components/text/Editor.vue'
import FileUploadButton from "@/components/file/FileUploadButton";
import DocumentList from "@/components/file/DocumentList";
import TextDocument from "@/components/ingram/documents/TextDocument";
import {mapGetters, mapState} from "vuex";
import httpClient from "@/plugins/axios";
import navHelper from "@/mixins/NavMixin";
export default {
  name: "SummerProject",
  components:{Editor, FileUploadButton, DocumentList, TextDocument},
  props:['project'],
  mixins:[navHelper],
  data(){
    return {
      states: states,
      saving: false,
      descriptionRule: true,
      menus:{
        startDate: false,
        endDate: false
      },
      hasChanges: false,
      newAttachments:[],
      newTextDoc:{name: '', content:''},
      docDialog: false,
      docText: '',
      iconUrl: require('@/assets/images/FileTypeTxt.png')
    }
  },
  computed:{
    ...mapState('app', ["organizations", "categories", "studentToken"]),
    ...mapGetters({
      user: 'app/getUser',
      isAdmin: 'app/isAdmin'
    }),
    formatStartDate(){
      return this.$luxon(this.project.startDate, "date_short");
    },
    formatEndDate(){
      return this.$luxon(this.project.endDate, "date_short");
    },
  },
  methods:{
    dirty(){
      this.hasChanges = true;
      this.$emit('hasChanges');
    },
    newFiles(files){
      const vm = this;
      vm.hasChanges = true;
      vm.newAttachments.forEach(x => {
        vm.project.attachments.push(x);
      });

      vm.newAttachments = [];

    },
    newText(){
      this.docDialog = true;
    },
    saveTextDoc(){
      const vm = this;

      let doc = {
        name: vm.newTextDoc.name,
        description: vm.newTextDoc.content,
        thumbUrl: '/images/FileTypeTxt.png',
        image: null,
        edit: false,
        deleted: false,
        documentType: 2,
      }

      vm.project.attachments.push(doc);
      vm.hasChanges = true;
      this.docDialog = false;
    },
    download(file){
      const a = document.createElement("a");
      a.href = file.url;
      a.setAttribute("download", file.name);
      a.click();
    },
    removeFile(file){
      if(file.id === undefined){
        let idx = this.project.attachments.indexOf(file);
        this.project.attachments.splice(idx,1);
      }else{
        file.deleted = true;
      }

    },
    cancel(){
      this.goBack();
    },
    save(){
      const vm = this;
      vm.saving = true;

      //build form data
      let data = new FormData();

      let model = vm.project;
      let metadata = [];

      vm.project.attachments.forEach((x, idx) => {
        if(!x.deleted && x.documentType === 1){
          if(x.url.indexOf('blob') <= 0) {
            data.append('photos', x.image, x.name);
            metadata.push({
              name: x.name,
              description: x.description
            });
          }
        }
      });

      const tokens = [vm.studentToken, vm.user.identifier];
      model.studentToken = tokens.find(x => {return x !== ''});
      model.photoMetadata = metadata;
      data.append('model', JSON.stringify(model));


      httpClient.post('/summerproject/update', data)
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Summer Project Saved'});
            //vm.$router.push('/');
            //vm.$router.go(-1);
            vm.$emit('saved');
            vm.hasChanges = false;
            vm.saving = false;
          }else{
            vm.$root.$emit('saveFailed', {message: response.data.message});
            vm.saving = false;
          }

        })
        .catch(function(error){
          console.log(error);
          vm.$root.$emit('saveFailed', {message: 'We apologize, an error has occurred. Please try again later.'});
          vm.saving = false;
        });

    },
  }
}
</script>

<style scoped>

</style>
