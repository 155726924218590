<template>
  <v-card flat>
    <v-card-title v-if="!hideActions">
      <v-col cols="8">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn color="primary" :to="{name: 'add-service'}">Add Service</v-btn>
      </v-col>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="service"
      :items-per-page="itemsPerPage"
      :hide-default-footer="hideFooter"
      :search="search"
      class="flex-grow-1"
    >

      <template v-slot:item.date="{ item }">
        <div>{{ item.date | luxon({output: {format:'MM/dd/yyyy'} }) }}</div>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="actions">
          <v-btn text color="primary" :to="getEditLink(item)" v-if="!hideActions">
            Edit
          </v-btn>

        </div>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ServiceList",
  props:{
    service:Array,
    hideActions: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  computed:{
    ...mapGetters({
      isAdmin: 'app/isAdmin'
    }),
  },
  data(){
    return{
      search: null,
      headers:[
        {text: 'Organization', value: 'organization'},
        {text: 'Contact', value: 'contact'},
        {text: 'Date', value:'date'},
        {text: 'Hours', value:'hours'},
        {text: 'Type', value:'type'},
        {text: 'Category', value:'category'},
        { text: '', sortable: false, align: 'right', value: 'action' }
      ],
    }
  },
  methods:{
    getEditLink(item){
      let link = {name: 'modify-service', params:{id: item.id}};
      if(this.isAdmin){
        link.name = 'admin-modify-service';
      }

      return link;
    }
  }
}
</script>

<style scoped>

</style>
